import * as React from 'react';
import { map as _map, find as _find } from 'lodash';

import Box from '@lce/slice_v2/Layout/Box';
import Button from '@lce/slice_v2/Elements/Button';
import Link from '@lce/slice_v2/Elements/Link';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';

import { INavigationLinkProps } from '@lce/intl-ui/src/Patterns/Navigation';
import { ILocationButton } from '@lce/intl-types/src/ILayout';

import * as Styled from '../MobileHeader.style';
import Pin from '../../assets/locator_pin_black.png';

export interface IOverlayProps {
  isOpen: boolean;
  nav: INavigationLinkProps[];
  franchiseeSelectorButton?: ILocationButton;
  locale: string;
}

const Overlay: React.FC<IOverlayProps> = ({ isOpen, nav, locale, franchiseeSelectorButton }) => {
  const { onClick, selectedFranchiseeId, franchisees } = franchiseeSelectorButton || {};
  const franchiseeName = _find(franchisees, (franchisee) => franchisee.id === selectedFranchiseeId)?.name;
  const showFranchiseeSelectorButton = selectedFranchiseeId && franchisees && franchisees.length > 1;
  
  return (
    <Box
      data-testid="mobile-overlay"
      sx={{
        backgroundColor: 'white',
        position: 'absolute',
        top: '70px',
        pointerEvents: 'auto',
        px: 4,
        left: 0,
        width: '100vw',
        transition: 'all 300ms ease-in',
        height: '100vh',
        zIndex: 99999,
        transform: `translateX(${isOpen ? '0' : '-100%'})`,
      }}
    >
      <Box sx={{ width: '100%', mx: 'auto', textAlign: 'center' }}>
        {showFranchiseeSelectorButton && 
          <Button
            data-testid="FranchiseeSelectorButton"
            onClick={onClick} 
            sx={Styled.FranchiseeSelectorButton}
          >
            <Image alt="" src={Pin} sx={Styled.Pin} />
            <Heading as="h2" sx={Styled.MobileMenuButton}>
              {franchiseeName}
            </Heading>
          </Button>
        }
        {_map(nav, ({ name, url, id }) => (
          <Link
            href={`/${locale.toLocaleLowerCase()}${url}`}
            key={id}
            variant="mainNav"
          >
            <Heading as="h2" sx={Styled.MobileMenuLink}>
              {name}
            </Heading>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default Overlay;
