import * as React from 'react';
import { includes as _includes, some as _some } from 'lodash';

import Heading from '@lce/slice_v2/Elements/Heading';
import Image from '@lce/slice_v2/Elements/Image';
import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';
import Text from '@lce/slice_v2/Elements/Text';
import Link from '@lce/slice_v2/Elements/Link';

import SpearImg from './assets/spear.png';
import * as Styled from './StartYourOrder.style';

export interface IStartYourOrder {
  locale: string;
  startYourOrderText: string;
  pickupButtonText: string;
  pickupButtonUrl: string;
  deliveryButtonText: string;
  deliveryUrl: string;
}

const StartYourOrder: React.FC<IStartYourOrder> = ({
  locale,
  startYourOrderText,
  pickupButtonText,
  pickupButtonUrl,
  deliveryButtonText,
  deliveryUrl,
}) => {
  const pathname = window.location.pathname;
  const pages = ['/contact-us', '/contacto', '/surcursales' ];
  const hideStartYourOrder = _some(pages, (page) => _includes(pathname, page));
  const hasPickupButton = pickupButtonText && pickupButtonUrl;
  const hasDeliveryButton = deliveryButtonText && deliveryUrl;
  const hasButton = hasPickupButton || hasDeliveryButton;
  const hasBothButtons = hasPickupButton && hasDeliveryButton;
  
  if (hideStartYourOrder || !hasButton) {
    return null;
  }

  return (
    <Flex data-testid="startyourorder-banner" sx={Styled.Wrapper}>
      <Heading sx={Styled.StartYourOrderText}>{startYourOrderText}</Heading>
      <Box sx={hasBothButtons ? Styled.ButtonContainer : Styled.OnlyOneButtonContainer}>
        {hasPickupButton && (
          <Link href={`/${locale.toLocaleLowerCase()}/${pickupButtonUrl}`} sx={Styled.Button} variant="skeleton">
            <Text sx={Styled.ButtonText}>{pickupButtonText}</Text>
          </Link>
        )}
        {hasBothButtons && (<Image src={SpearImg} sx={Styled.SpearDivider} />)}
        {hasDeliveryButton && (
          <Link href={`/${locale.toLocaleLowerCase()}/${deliveryUrl}`} sx={Styled.Button} variant="skeleton">
            <Text sx={Styled.ButtonText}>{deliveryButtonText}</Text>
          </Link>
        )}
      </Box>
    </Flex>
  );
};

export default StartYourOrder;
