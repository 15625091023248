import { SxStyleProp } from 'theme-ui';

export const MobileHeader: SxStyleProp = {
  bg: 'primary',
  width: '100%',
  height: '70px',
  p: '0px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  mb: '16px',
};

export const HeaderContainer: SxStyleProp = {
  width: '100%',
  height: '100%',
  maxWidth: '1280px',
  display: 'grid',
  gridTemplateColumns: '10% 1fr 10%',
  gridTemplateRows: '1fr',
  gap: '0px',
  rowGap: '0px',
  placeItems: 'center',
};

export const MenuButtonContainer: SxStyleProp = {
  gridArea: '1 / 1 / 2 / 2',
  display: 'flex',
  pl: 4,
};

export const LogoContainer: SxStyleProp = {
  gridArea: '1 / 2 / 2 / 3',
  mx: 'auto',
  px: 4,
  placeSelf: 'self-end',
};

export const MobileLCLogo: SxStyleProp = {
  width: ['250px', '288px', '288px'],
  verticalAlign: 'middle',
};

export const MobileMenuLink: SxStyleProp = {
  fontSize: [12, 14],
  my: '24px',
  lineHeight: 'inherit',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
};

export const MobileMenuButton: SxStyleProp = {
  fontSize: [12, 14],
  lineHeight: 'inherit',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
};

export const FranchiseeSelectorButton: SxStyleProp = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0px',
  background: 'transparent',
  color: 'black',
  cursor: 'pointer',
  gap: '8px',
  textTransform: 'uppercase', 
  m: '24px auto 0',
  p: '0',
  fontFamily: 'primary',
  fontWeight: 'bold',
  position: 'relative',
  ':hover': {
    textDecoration: 'underline',
  },
};

export const Pin: SxStyleProp = {
  width: '12px',
  height: 'auto',
  m: 0,
};
