import { SxStyleProp } from 'theme-ui';

export const Wrapper: SxStyleProp = {
  alignItems: 'center',
  flex: '1 1 auto',
  justifyContent: 'center',
  flexDirection: ['column', 'column', 'row'],
  gap: ['10px', '10px', '50px'],
  width: '100%',
  mb: 4,
};

export const StartYourOrderText: SxStyleProp = {
  fontSize: [12, 12, 14], 
  textTransform: 'uppercase',
};

export const ButtonContainer: SxStyleProp = {
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 200px) minmax(auto, 30px) minmax(auto, 200px)',
  alignItems: 'center',
  maxWidth: '478px',
  px: [14, 0],
};

export const OnlyOneButtonContainer: SxStyleProp = {
  display: 'flex',
  alignItems: 'center',
  width: '200px',
  px: [14, 0],
};

export const Button: SxStyleProp = {
  borderRadius: 0,
  alignItems: 'center',
  py: '12px',
  color: 'white',
  backgroundColor: 'primary',
  fontFamily: 'primary',
  textAlign: 'center',
  textDecoration: 'none',
  width: '100%',
  maxWidth: '200px',
};

export const ButtonText: SxStyleProp = {
  fontSize: ['16px', '18px', '20px'],
  textTransform: 'uppercase',
  lineHeight: 1,
  fontFamily: 'primary',
  fontWeight: 'bold',
};

export const SpearDivider: SxStyleProp = {
  maxHeight: '63px',
  height: '100%',
  mx: 'auto',
  width: 5,
  placeSelf: 'center',
};

export const EmptyContainer: SxStyleProp = {
  height: '16px',
  width: '100%',
};
